<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <search-form></search-form>
    <v-row justify="center">
      <v-col xs="12" sm="12" md="12">
        <preloader v-if="this.load==1" :color="loaderColor" :scale="1"></preloader>
        <oeref-details v-if="Object.keys( oeref ).length > 0" :oeref="oeref"></oeref-details>
      </v-col>
    </v-row>
  </v-container>
</template>



<script>
import api from "../../components/external/modules/shared/utils/api";

import Preloader from '../../components/external/modules/shared/views/WLoader.vue';
import OerefDetails from '../oeref/OerefDetails.vue';
import SearchForm from './SearchForm.vue';

import {
  VCol,
  VContainer,
  VRow,
} from "vuetify/lib";

export default {
  components: {
    VCol,
    VContainer,
    VRow,
    
    SearchForm,
    OerefDetails,
    Preloader,
  },

  data() {
    return {
        oeref: {},
        load: 1,
        loaderColor: "#0D47A1"
    };
  },

  methods: {
  },

  created() {
    let params = {
      kind: "oerefsearch",
      manNo: parseInt(this.$route.query.manno),
      manName: "",
      refNo: this.$route.query.refno,
      lang: this.storedLang
    }

    api
      .post("/oerefsearch/search", params)
      .then((res) => {
        this.load = 0;
        if (res.data.status === 200 && res.data.entity !== null) {
          this.oeref=res.data.entity;
        } else {
          console.log(new Error("Result status : "+res.data.status));
          console.log(res);
        }
      })
      .catch((err) => {
          console.log(new Error("Catching error : "));
          console.log(err);
      });
  },

  computed: {
    storedLang: function () {
      return localStorage.storedLang === undefined
      ? "FR"
      : localStorage.storedLang;
    },
  },
};
</script>

<style scoped>

</style>
