<template>
  
    <v-card class="elevation-12 mt-12 mb-6">
        <v-card-title class="Oeref">
            OE Reference Stats
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
            <v-simple-table>
              <tr v-for="(stat, i) in stats" :key="i">
                <td>{{ stat.statName }}</td>
                <td v-for="(statValue, j) in stat.stats" :key="j">{{ statValue.statValue }} ({{ statValue.statCount }}/{{ stat.statMaxCount }})</td>
              </tr>
            </v-simple-table>
        </v-card-text>
    </v-card>


</template>

<script>
import api from "../../components/external/modules/shared/utils/api";

import {
  //VBtn,
  VCard,
  //VCardActions,
  VCardText,
  VCardTitle,
  VDivider,
  VSimpleTable,
  //VCol,
  //VContainer,
//   VForm,
  //VRow,
  //VSpacer,
//   VTextField,
} from "vuetify/lib";

export default {
  name: 'oeref-stats',

  props: {
    oeref: { type: Object, default: null }
  },

  components: {
    // VBtn,
    VCard,
    //VCardActions,
    VCardText,
    VCardTitle,
    VDivider,
    VSimpleTable,
    //VCol,
    //VContainer,
    // VForm,
    //VRow,
    //VSpacer,
    // VTextField,
  },

  methods: {
    loadStats() {
      let params = {
          kind: "oerefstatssearch",
          manNo: parseInt(this.$route.query.manno),
          refNo: this.$route.query.refno,
          lang: this.storedLang
      }
  
      api
        .post("/oerefstatssearch/search", params)
        .then((res) => {
          if (res.data.status === 200 && res.data.entity !== null) {
            if (res.data.entity.stats == null) {
              this.stats = [];
            }
            else {
              this.stats = res.data.entity.stats;
            }
          } else {
            console.log(new Error("Result status : "+res.data.status));
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(new Error("Catching error : "));
          console.log(err);
        });
    },
  },

  data() {
    return {
      manufacturertitle: "",
      stats: null
    }
  },

  created() {
    this.loadStats();
  },

  computed: {
    storedLang: function () {
      return localStorage.storedLang === undefined
      ? "FR"
      : localStorage.storedLang;
    },
  },
}



</script>

<style scoped>
td {
  padding: 6px !important;
  border-bottom: 1px solid #b2cbfa !important;
}

th:not(:last-child), td:not(:last-child) {
    border-right: 1px dotted #b2cbfa;
}
</style>
