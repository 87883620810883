<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-card class="elevation-12 mt-12 mb-6">
        <v-card-title class="Oeref">
            <!-- <v-icon>mdi-alpha-k-box-outline</v-icon> -->
            OE Reference
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
            <v-simple-table>
              <tr>
                <td>Manufacturer</td>
                <td :title="manufacturertitle">{{ oeref.manName }}</td>
              </tr>
              <tr>
                <td>Reference</td>
                <td>{{ oeref.refNo }}</td>
              </tr>
            </v-simple-table>
        </v-card-text>
    </v-card>
    <v-row>
      <v-col cols="12">
        <articles-list :is-full-list="true"></articles-list>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <oeref-stats :oeref="this.oeref"></oeref-stats>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ArticlesList from '../article/ArticlesList.vue';
import OerefStats from './OerefStats.vue';

import {
  //VBtn,
  VCard,
  //VCardActions,
  VCardText,
  VCardTitle,
  VDivider,
  VSimpleTable,
  VCol,
  VContainer,
//   VForm,
  VRow,
  //VSpacer,
//   VTextField,
} from "vuetify/lib";

export default {
  name: 'oeref-details',

  props: {
    oeref: { type: Object, default: null }
  },

  components: {
    // VBtn,
    VCard,
    //VCardActions,
    VCardText,
    VCardTitle,
    VDivider,
    VSimpleTable,
    VCol,
    VContainer,
    // VForm,
    VRow,
    //VSpacer,
    // VTextField,
    ArticlesList,
    OerefStats,
  },

  methods: {
    
  },

  data() {
    return {
      manufacturertitle: "",
      articles: null
    }
  },

  created() {
    this.manufacturertitle = "ManNo : " + this.oeref.manNo;
  }
}



</script>

<style scoped>
td {
  padding: 6px !important;
  border-bottom: 1px solid #b2cbfa !important;
}

th:not(:last-child), td:not(:last-child) {
    border-right: 1px dotted #b2cbfa;
}
</style>
